html,
#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  background: #f2f2f2;
  line-height: 1.5;
  color: #666;
}

.capitalize {
  text-transform: capitalize;
}
